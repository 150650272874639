// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'mx',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '01136ee5ab166b5ce469cd1922f9d9b269ec87a0',
    releaseTagRef: 'b79c0cd',
    releaseTimestamp: '1737706228',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '9dcbb8b75f36846085dd1cc44fb6bf97',
      branch: '',
      braze: 'ab6fbe8b-6ba1-490f-b16f-20e704cf69c4',
      brazeCluster: 'sdk.iad-06.braze.com',
      bringg: '',
      googleMaps: 'AIzaSyAgFhX0sh2iKsBpm1mT6dvMGOpgmlxEk94',
      tomtom: '',
      launchDarkly: '65ce45aefcb38410509c0e1a',
      launchDarklyMobileKey: 'mob-ab0944c0-bc05-41e5-bb38-24c9b2eb9754',
      mParticle: 'us1-3c02710cf1470b4fbd811d43314a217e',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: 'merchant.com.prod.native.bk.prod',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://use2-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://use2-prod-bk-gateway.rbictg.com/graphql',
      region: 'us-east-2',
      userPoolClientId: '5gc2jf0dcrpghu6ki5tm1j05tp',
      userPoolId: 'us-east-2_92M1jvbIZ',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-W7XSS52',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.iad-05.braze.com/api/v3',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: 'C0004',
          consentCategoryBraze: 'C0003',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: 'C0002',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '01917fc6-ed8d-702c-9527-af6f8e803a13',
        },
      },
    }
  };